import CRMauth from '@/services/CRMinitAuth';

const list = query =>
  CRMauth.get(`${ process.env.VUE_APP_CRM_API }cbr_accounts/`, {
    params: query
  });

const updateCouseLocations = (ID, locations) =>
  CRMauth.patch(`${ process.env.VUE_APP_CRM_API }cbr_accounts/${ ID }/`, locations);

const patch = (ID, data) =>
  CRMauth.patch(`${ process.env.VUE_APP_CRM_API }cbr_accounts/${ ID }/`, data);

const importAccounts = data =>
  CRMauth.post(`${ process.env.VUE_APP_CRM_API }cbr_accounts/import_from_top_cbr/`, data);


export default {
  list,
  updateCouseLocations,
  importAccounts,
  patch
};