import { convertToDefaultDateHourFormat } from '@/util';

export const CBR_ACC = {
  ID: 'id',
  USERNAME: 'username',
  BUDGET: 'budget',
  PRODUCTS: 'products',
  SLOTS_EXPORT_TIME: 'slots_export_time',
  SLOTS_LOADING_STATUS: 'slots_loading_status',
  COURSE_LOCATIONS: 'cities',
  FOR_EXTENDED_EXAMS: 'for_extended_exams',
  //
  RESERVED_CANDIDATES: 'reserved_candidates',
  ACCEPTED_CANDIDATES: 'accepted_candidates',
  NEW_CANDIDATES: 'new_candidates',
  SUCCESS_RATE: 'success_rate',
};

export default class CbrAccModel {
  constructor(item = {}) {
    this.id = item[CBR_ACC.ID];
    this.username = item[CBR_ACC.USERNAME];
    this.budget = item[CBR_ACC.BUDGET];
    this.products = item[CBR_ACC.PRODUCTS];
    this.reserved_candidates = item[CBR_ACC.RESERVED_CANDIDATES];
    this.accepted_candidates = item[CBR_ACC.ACCEPTED_CANDIDATES];
    this.new_candidates = item[CBR_ACC.NEW_CANDIDATES];
    this.success_rate = item[CBR_ACC.SUCCESS_RATE];
    this.slotsLoadingStatus = item[CBR_ACC.SLOTS_LOADING_STATUS];
    this.slotsExportTime = item[CBR_ACC.SLOTS_EXPORT_TIME];
    this.courseLocations = item[CBR_ACC.COURSE_LOCATIONS];
    this.for_extended_exams = item[CBR_ACC.FOR_EXTENDED_EXAMS];

    this.apiAttributes = {
      [CBR_ACC.ID]: 'id',
      [CBR_ACC.USERNAME]: 'username',
      [CBR_ACC.BUDGET]: 'budget',
      [CBR_ACC.PRODUCTS]: 'products',
      [CBR_ACC.SLOTS_LOADING_STATUS]: 'slotsLoadingStatus',
      [CBR_ACC.SLOTS_EXPORT_TIME]: 'slotsExportTime',
      [CBR_ACC.COURSE_LOCATIONS]: 'courseLocations',
      [CBR_ACC.FOR_EXTENDED_EXAMS]: 'for_extended_exams',
      [CBR_ACC.RESERVED_CANDIDATES]: 'reserved_candidates',
      [CBR_ACC.ACCEPTED_CANDIDATES]: 'accepted_candidates',
      [CBR_ACC.NEW_CANDIDATES]: 'new_candidates',
      [CBR_ACC.SUCCESS_RATE]: 'success_rate'
    };
    this.animation = false;
  }

  merge(msg) {
    this.slotsLoadingStatus = msg[CBR_ACC.SLOTS_LOADING_STATUS];
    this.slotsExportTime = msg[CBR_ACC.SLOTS_EXPORT_TIME];
  }

  get formattedSlotsExportTime() {
    return convertToDefaultDateHourFormat(this.slotsExportTime);
  }
}