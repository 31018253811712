import api from '@/services/api/api-cbrAcc';
import CbrAccModel from './models/CbrAccModel';

export default class CbrAccService {
  async list(query) {
    try {
      let res = await api.list(query);
      if (!res) throw new Error('Something wrong and account not loaded. Please, reload page or try later.');
      return {
        results: res.map(item => new CbrAccModel(item))
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async importAccounts(ids) {
    try {
      let res = await api.importAccounts({ ids: ids });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateCourseLocations(ID, data) {
    try {
      let res = await api.updateCouseLocations(ID, {
        cities: data.locations,
        products: data.products
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateProducts(ID, products) {
    try {
      let res = await api.patch(ID, { products: products });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}