import WsInternal from '@/services/api/util/websocket-internal';

export default class ResultsExamBroadcast extends WsInternal {
  constructor(token) {
    super(process.env.VUE_APP_CRM_WS + `cbr_accounts_broadcast?auth_token=${ token }`);
  }

  startListen(callback) {
    this.ws.onmessage = event => {
      const wsMessageData = this.getMsgData(event);
      if (!wsMessageData) return;
      if (callback) callback(wsMessageData);
    };
  }

  close() {
    this.ws.close();
  }
}