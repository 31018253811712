import { CBR_ACC } from './models/CbrAccModel';

export const CBR_ACC_SLOTS_LOADING_STATUS = {
  INACTIVE: 'inactive',
  IN_PROGRESS: 'in_progress',
  FAILED: 'failed'
};

export const CBR_ACC_SLOTS_LOADING_STATUS_COLOR = {
  INACTIVE: '#58d08d',
  IN_PROGRESS: '#fb8c00',
  FAILED: '#ff5252'
};

export const CBR_ACCOUNTS_HEADERS = [
  {text: 'Username', value: CBR_ACC.USERNAME, sortable: true},
  {text: 'Budget', value: CBR_ACC.BUDGET, sortable: true},
  {text: 'Import status', value: CBR_ACC.SLOTS_LOADING_STATUS, sortable: false},
  {text: 'Reserved candidates', value: CBR_ACC.RESERVED_CANDIDATES, sortable: true},
  {text: 'Accepted candidates', value: CBR_ACC.ACCEPTED_CANDIDATES, sortable: true},
  {text: 'New candidates', value: CBR_ACC.NEW_CANDIDATES, sortable: true},
  {text: 'Success rate', value: CBR_ACC.SUCCESS_RATE, sortable: true},
  {text: 'Course locations', value: 'reserved', sortable: false},
  {text: 'Products', value: CBR_ACC.FOR_EXTENDED_EXAMS, sortable: false},
  {text: 'Imported at', value: CBR_ACC.SLOTS_EXPORT_TIME, sortable: false},
];