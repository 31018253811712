<template lang="pug">
  .page-container.cbr-accounts
    portal(to="toolbar-title")
      toolbar-title CBR Accounts

    .page-container__list
      list(
        :headers="headers"
        :list="list"
        :loading="loading"
        @change:sorting="updateSorting"
        @needToUpdate="loadList({})"
      )

    // Logs
    v-btn(color="primary" fab @click="loadLogs" data-test="log-btn" v-show="log").btn-floating
      v-icon(color="white") mdi-post

    v-navigation-drawer(
      v-model="logShowing"
      fixed
      right
      width="400"
      ref='drawer'
      v-if="log"
    )
      logs(:value="logShowing" @click:loadMore="loadLogsMore" :model="log" ref="log")
        template(v-slot:candidateName="props")
          span.link  {{ props.candidate.name }}
          span.link  - {{ props.candidate.number }},
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CBR_ACCOUNTS_HEADERS } from './core/cbrAcc-const'
import CbrAccService from './core/cbrAcc-service'
import errorsMixin from '@/mixins/errors.mixin'
import CrmAccBroadcast from '@/app/admin/modules/cbr_accounts/core/models/CbrAccBroadcast'
import CbrAccLogModel from '@/app/admin/modules/cbr_accounts/core/models/CbrAccLogModel'
import { LOG_PAGE } from '@/util/const'

export default {
  mixins: [errorsMixin],

  data: () => ({
    svc: new CbrAccService(),
    list: [],
    loading: false,
    headers: CBR_ACCOUNTS_HEADERS,
    cbrAccBroadcast: null,
    log: null,
    logShowing: false
  }),

  computed: {
    ...mapGetters({
      currentUser: 'user/current',
      courseCities: 'crmCourseCities/items',
      courseProducts: 'crmProductNames/items'
    }),
  },

  created() {
    this.init()
  },

  async mounted() {
    await this.loadData()
  },

  methods: {
    ...mapActions({
      getProducts: 'crmProductNames/list'
    }),

    init() {
      this.initBroadcast()
    },

    async initBroadcast() {
      let tokens
      tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.cbrAccBroadcast = new CrmAccBroadcast(tokens.access)
      this.cbrAccBroadcast.ws.addEventListener("message", this.updateCbrAccountByMessage)
    },

    updateCbrAccountByMessage(msg) {
      let _msg = this.cbrAccBroadcast.getMsgData(msg)
      let updatedItem = this.list.find(item => item.id === _msg.id)
      if (updatedItem) updatedItem.merge(_msg)
    },

    async loadData() {
      Promise.all[this.loadList(), this.getProducts()]
    },

    async loadList(query) {
      try {
        this.loading = true
        let {results} = await this.svc.list({...this.$route.query, ...query})
        this.list = results
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async updateSorting(ordering) {
      await this.$router.replace({query: {...this.$route.query, ordering: ordering}})
      await this.loadList({})
    },

    async setQuery(data, load) {
      await this.$router.replace({query: {...this.$route.query, ...data}})
      if (load) await this.loadList({})
    },

    //  Logs
    async initLogs(wsURL) {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.log = new CbrAccLogModel({page: [LOG_PAGE.IMPORT_TOP_CBR]}, `${ wsURL }?auth_token=${ tokens.access }`)
      this.log.ws.addEventListener("message", this.listenLogs)
    },

    listenLogs(msg) {
      this.log.listen(msg)
    },

    loadLogs() {
      if (this.logShowing) {
        this.logShowing = false
        return
      }
      this.$refs.log.loadData({...this.$refs.log.getDefaultPaginationParams()})
      this.logShowing = true
    },

    loadLogsMore() {
      this.$refs.log.loadMore({...this.$refs.log.getPaginationParams()})
    },
  },

  watch: {
    currentUser: {
      handler: function (newUserData) {
        if (newUserData && newUserData.logsWebsocketPath) this.initLogs(newUserData.logsWebsocketPath)
      },
      immediate: true
    }
  },

  beforeRouteLeave(to, from, next) {
    this.cbrAccBroadcast.ws.removeEventListener("message", this.updateCbrAccountByMessage)
    this.cbrAccBroadcast.ws.close()
    next()
  },

  provide: function () {
    return {
      svc: this.svc,
      setQuery: this.setQuery
    }
  },

  components: {
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    list: () => import('./components/CbrAccountsList.vue'),
    logs: () => import('@/app/admin/components/Log.vue')
  }
}
</script>
